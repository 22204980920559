import { state } from "@angular/animations";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
export type ModalName =
  | "websocket-disconnect"
  | "personal-data-modal"
  | "digital-signature-modal"
  | "tag-aval"
  | "terms-modal";

@Injectable({ providedIn: "root" })
export class ModalService {
  private modals: any[] = [];

  event = new Subject<{ id: string; state: "open" | "close" }>();

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  changeState(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    modal.open();
    this.event.next({ id, state: "open" });
  }

  close(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    modal.close();
    this.event.next({ id, state: "close" });
  }

  get state() {
    return this.modals;
  }
}
